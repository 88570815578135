import api from '@/utils/request'

let URL = process.env.VUE_APP_DK_URL
// debugger
// switch (process.env.NODE_ENV) {
//   case 'dev':
//     URL = process.env.VUE_APP_DK_URL
//     break;
//   case 'test':
//     URL = process.env.VUE_APP_DK_URL
//     break;
//   case 'production':
//     URL = process.env.VUE_APP_DK_URL
//     break;
//   default:

// }


// 视频列表
export function homeWorkDetail(params) {
  return api({
    method: 'get',
    url: '/app/homeWork/homeWorkDetail',
    params,
  })
}

export function GetLink(params) {
  return api({
    method: 'get',
    url: 'http://server-dk.jixedu.cn/app/version/getVersion',
    params,
  })
}
